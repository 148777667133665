/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Gibson Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Regular"), url("GibsonRegular.woff") format("woff");
}

@font-face {
  font-family: "Gibson Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Italic"), url("GibsonItalic.woff") format("woff");
}

@font-face {
  font-family: "Gibson Light";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Light"), url("GibsonLight.woff") format("woff");
}

@font-face {
  font-family: "Gibson Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Light Italic"), url("GibsonLightIt.woff") format("woff");
}

@font-face {
  font-family: "Gibson SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson SemiBold"), url("GibsonSemiBd.woff") format("woff");
}

@font-face {
  font-family: "Gibson Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Semibold Italic"), url("GibsonSemiBdIt.woff") format("woff");
}

@font-face {
  font-family: "Gibson Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Bold"), url("GibsonBold.woff") format("woff");
}

@font-face {
  font-family: "Gibson Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Gibson Bold Italic"), url("GibsonBoldIt.woff") format("woff");
}
