.input-group {
  display: block;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.input-label {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

#blitzscalability-form {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 30px;
}

#blitzscalability-form textarea {
  height: 130px;
}

#blitzscalability-form input:not([type="checkbox"]):not([type="radio"]) {
  width: 400px;
  height: 40px;
  padding-left: 15px;
  border: solid 1px #cacbcd;
  transition: border 0.2s linear, box-shadow 0.2s linear;
}

#blitzscalability-form span {
  color: red;
  margin-top: 10px;
  font-size: 15px;
}

#blitzscalability-form input:focus {
  border: solid 1px #cacbcd;
  background: #fff;
  outline: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
}

#blitzscalability-form select {
  width: 400px;
  height: 40px;
  padding-left: 15px;
  border: solid 1px #cacbcd;
  transition: border 0.2s linear, box-shadow 0.2s linear;
}

button:focus,
span:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: #fff;
  outline: 0;
  border: solid 1px #cacbcd;
  background: #fff;
  /* -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s; */
}

input[type="radio"] {
  margin-right: 7px;
}

.form-title {
  font-size: 22px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
}

.form-subtitle {
  font-size: 20px;
  font-weight: bold;
  margin: 10px auto 10px 25px;
}

.form-description {
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  color: #606060;
  margin: 6px auto 10px 25px;
}

.form-question {
  font-size: 20px;
  font-weight: bold;
  margin: 10px auto 10px 25px;
}

.form-multi-question {
  font-size: 20px;
  font-weight: bold;
  margin: 10px auto 10px 25px;
}

.form-list {
  margin: auto 0;
}

.form-dropdown {
  margin-bottom: 10px;
  width: 300px;
}

.form-options {
  font-size: 20px;
  margin: 3px auto 3px 25px;
}

.question-block {
  display: flex;
  flex-direction: column;
}

.input-block {
  margin: 8px auto 8px 0;
}

.form-error {
  margin-left: 25px;
}
