#company-modal {
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 70% !important;
  margin-top: 4rem;
  /*margin-left: 25%;*/
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  outline: 0;
  min-height: 500px;
  justify-content: center;
  align-items: center;
}

#company-modal .MuiTextField-root {
  vertical-align: unset !important;
}

#company-modal label {
  width: 140px;
  display: inline-block;
}
