.result-btn {
  font-size: 13px;
  font-weight: 600;
  line-height: 0.92;
  letter-spacing: 0.9px;
  text-align: center;
  color: #fff;
  font-family: "Raleway", sans-serif;
  background: #3d5467;
  padding: 17.5px 39px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  position: relative;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  margin-right: 20px;
}

#get-pdf-container {
  position: relative;
  height: 70px;
}

.comparison-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comparison-item {
  margin-top: 20px;
}

.social-share-result ul {
  padding: 0;
  text-decoration: none;
  margin: 20px 0;
}

.social-share-result ul li {
  list-style: none;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin: 0 10px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #f8ac1c;
  width: 40px;
  height: 40px;
  line-height: 40px;
  transition: all ease-in-out 0.3s;
}

.social-share-result li a {
  color: #f8ac1c;
  transition: all ease-in-out 0.3s;
  display: block;
  width: 100%;
  height: 100%;
}

.comparison-container {
  margin-bottom: 4rem;
}

.pdf-qa-section {
  margin-bottom: 80px;
}

.qa-item-title {
  font-size: 30px;
  font-family: "Gibson SemiBold";
}

.qa-section {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.qa-section div span {
  font-size: 130%;
}

.qa-section div h4 {
  font-size: 120%;
}

.qa-item-container {
  margin: 20px auto;
}

.qa-headings {
  font-size: 25px;
  font-family: "Gibson Regular";
}

.qa-headings-title {
  font-family: "Gibson Semibold Italic";
}

.qa-headings-notes {
  font-size: 20px;
  font-family: "Gibson Light";
}

.qa-title-group {
  font-family: "Gibson SemiBold";
  text-align: center;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 100px;
}

.score {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

#canva-container {
  /* text-align: center; */
}

.green,
.blue,
.pink,
.purple,
.red,
.orange,
.yellow {
  opacity: 0.2;
}

button:focus,
span:focus input:focus {
  outline: none !important;
}

.paper-footer {
  display: flex;
}

.paper-footer div {
  display: flex;
}

.MuiSelect-selectMenu {
  outline: none;
}

.cls-1,
.cls-15 {
  fill: #cee196;
}
.cls-1,
.cls-10,
.cls-11,
.cls-12,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9 {
  stroke: #000;
  stroke-miterlimit: 10;
}
.cls-1,
.cls-10,
.cls-11,
.cls-12,
.cls-6,
.cls-7,
.cls-9 {
  stroke-width: 2px;
}
.cls-2 {
  fill: #fff;
}
.cls-3 {
  font-size: 13.52px;
}
.cls-14,
.cls-3 {
  font-family: "Gibson Light";
  font-weight: 700;
}
.cls-4,
.cls-8 {
  opacity: 0.2;
}
.cls-5,
.cls-8 {
  fill: none;
}
.cls-18,
.cls-7 {
  fill: #a47db8;
}
.cls-17,
.cls-9 {
  fill: #ffdaa0;
}
.cls-21,
.cls-6 {
  fill: #f3b1d0;
}
.cls-10,
.cls-20 {
  fill: #a5dbf2;
}
.cls-11,
.cls-19 {
  fill: #fbf3a4;
}
.cls-12,
.cls-16 {
  fill: #f1929e;
}
.cls-13 {
  opacity: 0.3;
}
.cls-14 {
  font-size: 18px;
}
.cls-15 {
  fill: #f1929e;
}
.cls-16 {
  fill: #cee196;
}
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21 {
  stroke-width: 0.75px;
}
.canvas-pdf-des-title {
  font-size: 25px;
}
